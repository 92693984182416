import {createApp} from 'vue';
import {createPinia} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import translation from './plugin/translation.ts';
import formatNumbers from './plugin/formatNumbers.ts';

window.App = {
    lang: import.meta.env.VITE_LOCALE,
};

window.locale = 'nl-NL';

const pinia = createPinia();
const app = createApp({});
// app.config.warnHandler = function (msg, vm, trace) {
//     return null
// }
// Dynamisch alle componenten laden uit de `components` map met Vite
const modules = import.meta.glob('/resources/assets/components/**/*.vue', {eager: true});
Object.entries(modules).forEach(([path, component]) => {
    // Extract component name from file path
    const componentName = path.split('/').pop().replace('.vue', '');
    app.component(componentName, component.default);
});

app.config.compilerOptions.isCustomElement = (tag) => {
    return tag.startsWith('center'); // (return true)
};

pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(translation, {lang: window.locale});
app.use(formatNumbers, {lang: window.locale});
app.mount('#app');
