<template>
    <div
        v-if="showComp"
        @load="hasNotBeenValidated">
        <div
            v-if="showNotif.error"
            class="mao-notification discountError warn">
            <i class="fa fa-exclamation-triangle"></i>
            <span>{{ __('De ingevoerde actiecode is niet geldig') }}.</span>
        </div>
        <div
            v-if="showNotif.succes"
            class="mao-notification discountSuccess info">
            <i class="fa fa-exclamation-triangle"></i>
            <span>{{ __('De ingevoerde actiecode is geldig en wordt toegepast') }}</span>
        </div>

        <div
            :class="siteSettingsStore.isChannel == 'cpd' ? 'cart-table' : 'mao-section-bordered'"
            class="mao-checkout-discount">
            <div class="row">
                <div class="col-sm-10 col-md-6 col-lg-6">
                    <span class="mao-checkout-discount__label">
                        <strong>{{ __('Heb je een actiecode') }}?</strong>
                    </span>
                </div>
                <div class="col-sm-10 col-md-6 col-lg-6">
                    <div class="discount-code">
                        <input
                            type="text"
                            :placeholder="__('Voer je actiecode in')"
                            id="discountCode"
                            v-model="discountCode" />
                        <button
                            @click="validateCode"
                            class="mao-button black field-left">
                            {{ __('Toepassen') }}
                            <i class="fa fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {shoppingCartStore, siteSettings} from './Store';
    import axios from 'axios';
    import {onMounted, ref} from 'vue';

    const store = shoppingCartStore();
    const siteSettingsStore = siteSettings();
    const discountCode = ref(store.couponDiscount.couponCode);
    const showNotif = ref({succes: false, error: false});
    const showComp = ref(true);
    const props = defineProps({
        step: {default: 0},
    });

    onMounted(() => {
        showComp.value = store.couponDiscount.couponCode === '';
    });

    const validateCode = () => {
        store.setRequestDone(false);
        axios
            .post(window.route.shoppingcart.validatediscountcode, {
                discountCode: discountCode.value,
                nextButtonAction: $('#cartSumaryNextButtonAction').attr('href'),
                step: props.step,
            })
            .then((res) => {
                if (res.data.couponDiscountCode === null) {
                    showNotif.value.error = true;
                    showNotif.value.succes = false;
                } else {
                    showNotif.value.succes = true;
                    showNotif.value.error = false;
                    $('#shoppingCartSummary').html(res.data.message.shoppingCartSummary);
                    store.couponDiscount = {
                        couponCode: res.data.couponDiscountCode,
                        discount: res.data.couponDiscountTotal * siteSettingsStore.btw,
                    };
                    MAO_Overview.init();
                    MAO_Payment.init();
                }
            })
            .catch(function () {
                showNotif.value.error = true;
                showNotif.value.succes = false;
            })
            .then(() => store.setRequestDone(true));
    };
</script>

<style scoped></style>
